import "../styles/reset.css";
import GoogleTagManager from "../lib/google-tag-manager";

// Pass `err` as workaround for https://github.com/vercel/next.js/issues/8592
function MyApp({ Component, pageProps, err }) {
  return (
    <>
      <Component {...pageProps} err={err} />
      <GoogleTagManager gtmId="GTM-NVBGTH" />
    </>
  );
}

export default MyApp;
